@import '../../../../public-scss/colors.scss';

$toggleTime: 0.75s;

.card-service {
  background: $accent;
  color: black;
  width: 24rem;
  border-radius: 1.5rem;
  padding: 2rem;
  margin: 2em;

  .advantages-service {
    padding-left: 0.735rem;
    .advantage-circle {
      position: relative;
      top: -0.1rem;
      right: 0.4rem;
    }
  }

  .description {
    max-height: 0;
    overflow: hidden;
    transition:
      max-height $toggleTime ease-in-out,
      opacity $toggleTime ease-in-out,
      visibility $toggleTime ease-in-out;
    opacity: 0;
    visibility: hidden;
    letter-spacing: -1px;
  }

  .expanded.description {
    max-height: 1000px; /* Adjust this value based on your content height */
    opacity: 1; /* Show the description */
    visibility: visible; /* Show the description */
  }
}

.label-service {
  border-radius: 2rem;
  font-weight: 800;
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 0.2rem;
  font-size: 1.5rem;
  text-align: center;
}

.expand-button {
  border: 0.15rem black solid;
  border-radius: 1.5rem;
  .expand-icon {
    transition: transform $toggleTime/2 ease-in-out;
  }
}

.expanded.expand-icon {
  transform: rotate(180deg);
}
