@import '../../../../public-scss/colors.scss';

.back-btn {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: $accent !important;
    border-color: $accent !important;
    color: $white !important;
  }

  background-color: $white;
  color: $accent;
  border-color: $accent;
  font-weight: 700;
  border-width: 0.15rem;
  border-radius: 2rem;
  width: 10rem;
  height: auto;

  .back-btn-icon {
    font-size: 1.5rem;
    position: relative;
    left: 1rem;
    transition: left 0.3s ease-in-out;
  }

  &:hover {
    background-color: $accent;
    color: $white;
    border-color: $accent;

    .back-btn-icon {
      left: -0.5rem;
      transition: left 0.3s ease-in-out;
    }
  }
}
