@import '../../../../public-scss/colors.scss';

.img-project {
  transition: 0.3s;
  border-radius: 1.25rem;
  width: 5rem;
  border: 3px solid white;
  padding: 0.2rem;
}

.card-project {
  width: auto;
  border-radius: 3rem;
  color: white;
  background-color: $black !important;

  a {
    color: white;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      color: #ffa726;

      .img-project {
        border-color: #ffa726;
      }
    }
  }
}

.main-text-project {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  min-height: 32rem;
  margin-top: 1rem;
  padding: 0.75rem;
  color: $black;
  border-radius: 1.75rem;

  h4 {
    font-weight: 700;
  }
}

.label-project,
.project-btn {
  padding: 0.5rem;
  border-radius: 2rem;
  width: 10rem;
  font-weight: 800;
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 0.2rem;
  font-size: 1.1rem;
}

.paragraph-project {
  font-weight: 700 !important;
  font-family: 'Roboto', sans-serif;
  font-size: 1.05rem;
}

.project-btn {
  text-decoration: none;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.freelance {
  background-color: #ffa726 !important;
  color: black !important;
  transition: 0.3s;
  border: 2px solid black;
}

.project-btn.freelance {
  &:hover {
    background-color: black !important;
    color: #ffa726 !important;
  }
}

.internship {
  background-color: lightblue !important;
  color: black !important;
  transition: 0.3s;
  border: 2px solid black;
}

.project-btn.internship {
  &:hover {
    background-color: black !important;
    color: lightblue !important;
  }
}

.student {
  background-color: lightcoral !important;
  color: black !important;
  transition: 0.3s;
  border: 2px solid black;
}

.project-btn.student {
  &:hover {
    background-color: black !important;
    color: lightcoral !important;
  }
}

@-webkit-keyframes CardAnimation {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}

@-moz-keyframes CardAnimation {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}

@-o-keyframes CardAnimation {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}

@keyframes CardAnimation {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}

.ul-project {
  padding-inline-start: 1.5rem;
}

/* Media query for smaller screens */
@media (max-width: 767px) {
  .main-text-project {
    min-height: 25rem;
  }
}

/* Media query for smaller screens */
@media (max-width: 580px) {
  .project-title {
    justify-content: center !important;
  }
}

/* Media query for larger screens */
@media (min-width: 1335px) {
  .main-text-project {
    min-height: 25rem;
  }
}
