@import '../../../public-scss/colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.top-navbar {
  max-width: 40rem;
  max-height: 15rem;
  background-color: $gray;
  border-radius: 2rem;
}

.contact-us {
  background-color: $black;
  border-radius: 2rem;
}

.toggler {
  background-color: transparent !important;
  border: none !important;
  transition:
    background-color 0.3s,
    color 0.3s,
    border 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .menu-icon,
  .close-icon {
    color: $black;
    font-size: 2rem;
    opacity: 1;
    visibility: visible;
    transition:
      opacity 0.3s,
      visibility 0.3s;
  }

  .close-icon {
    position: relative;
    display: none;
    /* Initially hidden */
  }

  &.active {
    .menu-icon {
      opacity: 0;
      visibility: hidden;
      display: none;
    }

    .close-icon {
      display: block;
      opacity: 1;
      visibility: visible;
    }
  }
}

.nav-links {
  font-size: 2rem;
  color: $black;
  transition: 0.6s;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 500;
  border-radius: 1.5rem;
  margin-bottom: 0.25rem;

  &:hover {
    color: $white !important;
    background: $black;
    border-radius: 2rem;
    text-decoration: underline;
  }
}

.current {
  text-decoration: underline;
}

.nav-logo,
.nav-links {
  margin-left: 1rem;
  margin-right: 1rem;
}

.nav-bar-whole {
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
