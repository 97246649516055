@import '../../../../public-scss/colors.scss';

.get-in-touch-btn-reversed {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: $accent !important;
    border-color: $black !important;
    color: $black !important;
  }

  &:disabled {
    background-color: $black;
    border-color: $black;
    color: $accent;
    font-weight: 900;
    border-width: 0;
    border-radius: 2rem;
    width: 15rem;
    height: auto;
    text-decoration: none !important;
  }

  background-color: $black;
  border-color: $black;
  color: $accent;
  font-weight: 900;
  border-width: 0;
  border-radius: 2rem;
  width: auto;
  max-width: 15rem;
  min-width: 12rem;
  height: auto;
  text-decoration: none !important;
  .get-in-touch-btn-icon {
    font-size: 1.5rem;
    position: relative;
    left: 0.85rem;
    transition: left 0.3s ease-in-out;
  }
  &:hover {
    outline: none !important;
    background-color: $accent !important;
    color: $black !important;
    animation: pulsate 1.2s infinite;
    border-color: $black !important;

    .get-in-touch-btn-icon {
      left: 1.75rem;
    }
  }

  @keyframes pulsate {
    0%,
    100% {
      box-shadow: 0 0 0 0.15rem $black;
    }
    50% {
      box-shadow: 0 0 0 0.25rem $accent;
    }
  }
}

.get-in-touch-btn {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: $black !important;
    border-color: $accent !important;
    color: $accent !important;
  }

  &:disabled {
    background-color: $accent;
    color: $black;
    border-color: $accent;
    font-weight: 900;
    border-width: 0;
    border-radius: 2rem;
    width: 15rem;
    height: auto;
    text-decoration: none !important;
  }

  background-color: $accent;
  color: $black;
  border-color: $accent;
  font-weight: 900;
  border-width: 0;
  border-radius: 2rem;
  width: 15rem;
  height: auto;
  text-decoration: none !important;
  .get-in-touch-btn-icon {
    font-size: 1.5rem;
    position: relative;
    left: 0.85rem;
    transition: left 0.3s ease-in-out;
  }
  &:hover {
    outline: none !important;
    background-color: $black !important;
    color: $accent !important;
    animation: pulsate 1.2s infinite;
    border-color: $accent !important;

    .get-in-touch-btn-icon {
      left: 1.75rem;
    }
  }

  @keyframes pulsate {
    0%,
    100% {
      box-shadow: 0 0 0 0.15rem $accent;
    }
    50% {
      box-shadow: 0 0 0 0.25rem $black;
    }
  }
}
