@import '../../../public-scss/colors.scss';

.tagline {
  font-size: 3rem;
  text-align: center;
  line-height: 3.6rem;
}

.upper-pane {
  background-color: $black-faded;
  border-radius: 2rem;
  color: $white;
  margin-top: 4rem;
  margin-left: 5rem;
  margin-right: 5rem;
  position: relative;
  z-index: 1;
}

.introduction-card {
  background-color: $accent;
  border-radius: 2rem;
  color: $black;
  margin: 4rem 5rem 4rem 5rem;
  padding: 2rem;
}

.home-container {
  position: relative;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.text-under-tag-line {
  width: 70%;
}

.what-we-offer-btn {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: $black !important;
    border-color: $accent !important;
    color: $accent !important;
  }
  transition: 0.5s;
  background-color: $black;
  color: $accent;
  border-color: $accent;
  font-weight: 900;
  border-width: 0.15rem;
  border-radius: 2rem;
  width: 15rem;
  height: auto;

  .what-we-offer-btn-icon {
    font-size: 1.5rem;
    position: relative;
    left: 0.5rem;
  }

  &:hover {
    outline: none !important;

    background-color: $black !important;
    color: $accent !important;
    border-color: $accent !important;
    box-shadow: 0 0 0 0.1rem $accent;

    .what-we-offer-btn-icon {
      animation: pulsate-icon 1.5s infinite;
    }
  }

  @keyframes pulsate-icon {
    0%,
    100% {
      color: $accent;
    }
    50% {
      color: $black;
    }
  }
}

/* Media query for smaller screens */
@media (max-width: 820px) {
  .tagline {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    line-height: 2.4rem;
  }

  .upper-pane {
    margin-top: 0;
  }

  .upper-pane {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media (max-width: 575px) {
  .what-we-offer-btn {
    margin: auto !important;
  }

  .introduction-card {
    margin: 1rem;
    padding: 1.25rem;
  }
}
