@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

$white: #ffffff;
$black-faded: rgba(27, 29, 29, 0.5);
$black: rgba(27, 29, 29, 1);
$gray: #3e3047;
$accent: #ffa726;
$purple-blue: #4e57f7;

.bg-fake-white {
  background-color: $white;
}

.white {
  color: $white;
}

.black {
  color: $black;
}

.gray {
  color: $gray;
}

.accent {
  color: $accent;
}

.purple-blue {
  color: $purple-blue;
}

.bg-white {
  background-color: $white;
}

.bg-black {
  background-color: $black;
}

.bg-gray {
  background-color: $gray;
}

.bg-accent {
  background-color: $accent;
}

.bg-purple-blue {
  background-color: $purple-blue;
}

.hard-font-style {
  font-family: 'Bebas Neue', sans-serif;
}

.roboto-font-style {
  font-family: 'Roboto', sans-serif;
}
