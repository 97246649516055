@import './public-scss/colors.scss';

html {
  scroll-behavior: smooth !important;
}

@media (max-width: 991px) {
  .card-service,
  .card-product {
    width: 22rem;
    font-size: smaller;

    .advantages-service .advantage-circle,
    .advantages-product .advantage-circle {
      top: -0.3rem;
      right: 0.4rem;
    }
  }
}

@media (max-width: 500px) {
  .card-service,
  .card-product {
    width: 95%;
  }
}
