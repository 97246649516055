@import '../../../public-scss/colors.scss';

.contact-card {
  background-color: $black;
  color: white;
  border-radius: 2.5rem;
  padding: 1rem;
  width: 18rem;
  height: 18rem;
  .contact-logo-link {
    font-size: 4rem;
    color: $accent;
    text-decoration: none;
  }
  .contact-title {
    font-size: 1.2rem !important;
  }
}

.or-hr {
  width: 45%;
  margin-right: 0.6rem;
  margin-left: 0.6rem;
  border: 2px solid $black;
  border-radius: 2rem;
}

.contact-form-wrapper {
  max-width: 60rem;
  width: auto;
  padding: 1.5rem;
  border: 2px solid $black;
  border-radius: 2rem;
}

input,
textarea {
  transition: 0.3s;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 1px $accent solid !important;
  }
}
