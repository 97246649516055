@import '../../../public-scss/colors.scss';

.page-max-width {
  max-width: 85rem;
}

.main-wrapper {
  margin-top: 4rem;
  margin-left: 3rem;
  margin-right: 3rem;
}

@media (max-width: 991px) {
  .main-wrapper {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
